<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import moment from 'moment'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { useAuth } from '~/store/auth'
import { useUser } from '~/store/user'

const authStore = useAuth()
const userStore = useUser()
const cookieVerify = useCookie('verifyTimer')
const verifyTimer = computed(() => typeof cookieVerify.value === 'string' ? JSON.parse(cookieVerify.value) : cookieVerify.value)
const user = computed(() => userStore.parsed)
const openModal = ref(false)
const isLoading = ref(false)
const isOTPDone = ref(false)
const isVerifyDone = ref(false)
const errorVal = ref()
const isPhoneValid = ref(false)
const form = reactive({ phone: null, code: null })
const errorList = computed(() => [
  { id: 'ERR_MAX_REQUEST_EXCEEDED', value: 'You are exceeding daily login attempt' },
  { id: 'ERR_WITHIN_COOLDOWN_PERIOD', value: 'Please wait a few minutes and try again' },
  { id: 'ERR_INVALID_UNKNOWN', value: 'System Error' },
])

onMounted(() => {
  // if no verifyTimer cookie set, then set it first with current datetime
  if (verifyTimer.value === undefined || (!isEmpty(verifyTimer.value) && verifyTimer.value?.email !== userStore.parsed?.email)) { cookieVerify.value = JSON.stringify({ email: userStore.parsed?.email, datetime: moment().utc().format(), isClosed: false }) }
  else {
    // if already exist, need to check if its already 24 hour or not
    const lastExecutionTime = moment(verifyTimer.value?.datetime).utc().format()
    const nextDay = moment(lastExecutionTime).add(1, 'day').utc().format()
    const currentTime = moment().utc().format()
    const passOneDay = moment(currentTime).isSameOrAfter(nextDay)

    // if 24 hour already pass, need to set new datetime + open the alert
    // if not 24 hour, do nothing
    if (passOneDay)
      cookieVerify.value = JSON.stringify({ email: userStore.parsed?.email, datetime: moment().utc().format(), isClosed: false })
  }
})

function setAlertClose() {
  cookieVerify.value = JSON.stringify({ email: userStore.parsed?.email, datetime: verifyTimer.value.datetime, isClosed: true })
  useSetTracking('validate_phone_reminder_close')
}

const checkIfPhoneValid = () => isPhoneValid.value && form.phone.length > 6
const rules = {
  phone: {
    required: helpers.withMessage('Phone is required', required),
    phoneValid: helpers.withMessage('Please enter a valid phone number', checkIfPhoneValid),
  },
}
const v$ = useVuelidate(rules, form, { $autoDirty: true, $lazy: true })

// CODE FOR TEL INPUT
const dropdownOptions = { showDialCodeInList: true, showDialCodeInSelection: false, showSearchBox: true, showFlags: true }
const inputOptions = { showDialCode: true, required: true }
function phoneValidationChange(value: { valid: boolean | undefined; countryCode: string }) {
  isPhoneValid.value = value.valid
}

function modalOpen() {
  errorVal.value = null
  isLoading.value = false
  openModal.value = true
  isOTPDone.value = false
  isVerifyDone.value = false
  isPhoneValid.value = false
  form.phone = userStore.parsed?.phone

  useSetTracking('validate_phone_reminder_verify_click')
}

async function checkPhone() {
  errorVal.value = null
  isLoading.value = true

  const isFormCorrect = await v$.value.$validate()
  if (isFormCorrect) {
    const data = await authStore.checkExist('phone', form.phone.replaceAll(' ', ''), true)
    if (data.status) {
      if (data.exist) { errorVal.value = 'Phone already exist' }
      else {
        const otpRes = await authStore.sendOTP(form.phone.replaceAll(' ', ''), 'verify', 'whatsapp')
        if (otpRes.status)
          isOTPDone.value = true
        else errorVal.value = errorList.value.find(val => val.id === otpRes.msg)?.value || otpRes.msg
      }
    }
    else { errorVal.value = errorList.value.find(val => val.id === data.msg)?.value || data.msg }
  }

  isLoading.value = false
}

// auto close modal after success verify
const timer = ref(5)
const { pause, resume, isActive } = useIntervalFn(() => {
  if (timer.value <= 1) {
    openModal.value = false
    pause()
  }
  timer.value--
}, 1000)

async function onSubmit(codeVal: string) {
  form.code = codeVal
  if (codeVal.length === 4) {
    errorVal.value = null
    isLoading.value = true

    const { data, error } = await useCompanyApi('/talent/auth/change', {
      useAuth: true,
      useApiKey: true,
      method: 'POST',
      body: {
        phone: form.phone.replaceAll(' ', ''),
        code: codeVal,
      },
      key: 'submit',
      initialCache: false,
    })

    const resp = data.value as { success: boolean; msg: string }
    if (!error.value && resp.success) {
      userStore.parsed = { ...userStore.parsed, phone: form.phone.replaceAll(' ', ''), phoneVerified: true }
      isVerifyDone.value = true
      resume()
    }
    else {
      errorVal.value = resp?.msg || error?.value?.data?.msg
    }
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <div v-if="!user.phoneVerified && !verifyTimer?.isClosed" class="bg-[#FFFBEB] border border-[#FBBF24] rounded-md px-4 py-3.5 mb-4">
      <div class="flex md:items-center mb-2">
        <div><Icon name="material-symbols:info-outline" class="w-5 h-5 mr-2 text-[#D97706]" /></div>
        <p class="text-sm">
          Verify your phone number for job alerts and employer messages.
        </p>
        <Icon name="material-symbols:close" class="w-5 h-5 ml-auto cursor-pointer" @click="setAlertClose()" />
      </div>
      <p class="text-[#5843E5] font-bold text-xs pl-7 cursor-pointer" @click="modalOpen()">
        Verify now →
      </p>
    </div>
    <TransitionRoot appear :show="openModal" as="template">
      <Dialog as="div" class="relative z-100" :static="true">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" style="backdrop-filter: blur(10px)" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-lg p-6 transform overflow-hidden rounded-md bg-white align-middle shadow-xl transition-all">
                <div v-if="isVerifyDone" class="text-center relative min-h-xs">
                  <div class="grid gap-y-6 mt-10">
                    <Icon name="material-symbols:check-circle" class="w-24 h-24 mx-auto text-[#059669]" />
                    <div class="text-sm">
                      <p class="text-3xl font-bold mb-2">
                        You're all set
                      </p>
                      Now you can receive job alerts and messages from recruiters.
                    </div>
                  </div>

                  <div class="absolute bottom-0 w-full grid">
                    <BaseAtomsButton size="md" outline classes="font-semibold border-0 shadow-none" @click="openModal = false">
                      Close
                    </BaseAtomsButton>
                  </div>
                </div>
                <div v-else class="space-y-6 relative min-h-sm">
                  <Icon name="logos:whatsapp-icon" class="w-12 h-12 text-[#5843E5]" />
                  <div>
                    <p class="text-2xl font-bold mb-2">
                      {{ isOTPDone ? 'Check your WhatsApp' : 'Verify via Whatsapp' }}
                    </p>
                    <div class="text-sm text-[#666666]">
                      {{ isOTPDone ? `We have sent a code to ${form.phone}` : 'We will send a 4-digit code to your phone via WhatsApp . Is this number correct?' }}
                    </div>
                  </div>
                  <div v-if="!isOTPDone">
                    <VueTelInput v-model="form.phone" :class="[v$.phone.$invalid ? 'input-danger' : 'input-primary']" class="input-base flex" :input-options="inputOptions" :auto-format="true" :dropdown-options="dropdownOptions" :valid-characters-only="true" mode="international" @validate="phoneValidationChange" />
                    <BaseMoleculesInputError class="mt-2" :invalid-checker="v$.phone.$invalid || !isEmpty(errorVal)" :error-val="v$.phone.$errors[0]?.$message.toString() || errorVal" />
                  </div>
                  <div v-else>
                    <ContinueOTPBox :digit-count="4" :is-error="errorVal" @update:otp="onSubmit($event)" />
                    <ContinueResendOTP class="mt-4" :recipient="form.phone" type="verify" channel="whatsapp" :timeout="30" />
                  </div>

                  <div :class="`absolute bottom-0 w-full grid ${isOTPDone ? '' : 'grid-cols-2'} gap-4`">
                    <BaseAtomsButton size="md" outline classes="font-semibold border-0 shadow-none" @click="openModal = false">
                      Cancel
                    </BaseAtomsButton>
                    <BaseAtomsButton v-if="isLoading" disabled size="md" classes="font-semibold">
                      <div class="flex items-center">
                        <Icon name="eos-icons:loading" class="h-5 w-5 text-white mx-auto mr-2" aria-hidden="true" />
                        <span> Loading ...</span>
                      </div>
                    </BaseAtomsButton>
                    <BaseAtomsButton v-if="!isLoading && !isOTPDone" size="md" classes="font-semibold" @click="checkPhone()">
                      Continue
                    </BaseAtomsButton>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
